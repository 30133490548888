import { ShiftMaintenanceConstant } from "presentation/constant/Shift/ShiftMaintenanceConstant";
import { useShiftMaintenanceVM } from "presentation/hook/Shift/useShiftMaintenanceVM";
import { useMessageBarVM } from "presentation/hook/useMessageBar";
import { useShiftMaintenanceTracked } from "presentation/store/Shift/ShiftMaintenanceProvider";
import { memo, useCallback, useState } from "react";
import { HPHBreadcrumb, IconButton, Loader } from "veronica-ui-component/dist/component/core";
import { Breadcrumb, Sidebarheader, StyledAction } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";
const ShiftEditPanelTitleBar: React.FC = () => {
    const [shiftState] = useShiftMaintenanceTracked();
    const shiftVM = useShiftMaintenanceVM();
    const messageBarVM = useMessageBarVM();
    const { currentSelectedRow, masterState } = shiftState;
    const { editingEntity, isAdd, isEditable, isRead } = masterState;

    const [isLoading, setIsLoading] = useState(false);
    const currentEntity = isRead ? currentSelectedRow : editingEntity;
    const handleReset = useCallback(async () => {
        shiftVM.onReset();
    }, [shiftVM]);
    const handleEdit = useCallback(() => {
        shiftVM.onEditClick(currentEntity);
    }, [currentEntity, shiftVM])
    const handleSave = useCallback(async () => {
        if (!currentEntity.shiftType) {
            messageBarVM.showError("Shift Type is mandatory.");
            return;
        }
        if (!currentEntity.shiftDay) {
            messageBarVM.showError("Shift Day is mandatory.");
            return;
        }
        if (!currentEntity.shiftCode) {
            messageBarVM.showError("Shift Code is mandatory.");
            return;
        }
        if (currentEntity.startTimeHour === null && currentEntity.aheadTimeMin === null) {
            messageBarVM.showError("Start Time is mandatory.");
            return;
        }
        if (currentEntity.endTimeHour === null && currentEntity.endTimeMin === null) {
            messageBarVM.showError("End Time is mandatory.");
            return;
        }
        setIsLoading(true);
        try {
            shiftVM.onSaveClicked();
            const res = await shiftVM.onSave(currentEntity, isAdd);
            if (!!!res || !res.success) {
                messageBarVM.showWarining(res?.data ?? 'Save data failed.');
            } else {
                shiftVM.onClose();
                setIsLoading(true);
            }
        } catch (error) {
            messageBarVM.showError('Save data failed.');
        } finally {
            setIsLoading(false)
        }

    }, [currentEntity, isAdd, messageBarVM, shiftVM]);
    return <Sidebarheader style={{ width: '100%' }}>
        {isLoading && <Loader Indicator="Spinner" size="Medium" />}
        <Breadcrumb>
            <HPHBreadcrumb breadcrumbData={[{ title: ShiftMaintenanceConstant.SCREEN_CATEGORY },
            { title: ShiftMaintenanceConstant.TITLE }]} onCurrentClick={() => { }}>
            </HPHBreadcrumb>
        </Breadcrumb>
        <StyledAction>
            {!(isAdd || isEditable) && <IconButton fileName='Icon-pen' size='medium' toolTipText={'Edit'} toolTipArrow={false} onClick={handleEdit} />}
            {(isAdd || isEditable) && <IconButton fileName="Icon-reset" size="medium" toolTipArrow={false} toolTipPlacement="bottom" toolTipText={'Reset'} onClick={handleReset} />}
            {<IconButton fileName="Icon-cross" size="medium" disabled={false} toolTipArrow={false} toolTipPlacement="left" toolTipText={'Close'} onClick={shiftVM.onClose} />}
            {(isAdd || isEditable) && <IconButton fileName="Icon-tick" size="medium" disabled={false} toolTipArrow={false} toolTipPlacement="bottom" toolTipText={'Save'} onClick={() => handleSave()} />}
        </StyledAction>
    </Sidebarheader>
}
export default memo(ShiftEditPanelTitleBar); 
