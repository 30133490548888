import { useShiftMaintenanceVM } from "presentation/hook/Shift/useShiftMaintenanceVM";
import { useShiftMaintenanceTracked } from "presentation/store/Shift/ShiftMaintenanceProvider";
import { memo, useEffect, useState } from "react";
import { Loader, SliderPanel } from "veronica-ui-component/dist/component/core";
import ShiftTablePanel from "./ShiftTablePanel";
const ShiftMaintenance:React.FC = () => { 
    const [isLoading, setIsLoading] = useState(false); 
    const [shiftState] = useShiftMaintenanceTracked(); 
    const shiftVM = useShiftMaintenanceVM(); 
    const {isBackMaster} = shiftState; 

    useEffect(() => { 
        const initialScreen = async() => {    
            setIsLoading(true); 
            try {      
                const results = await Promise.allSettled([ 
                    shiftVM.onSearch(),
                    shiftVM.loadDropdownOption(), 
                ]); 
                results.forEach((result, index) => { 
                    if (index === 0 && result.status === 'fulfilled') { 
 
                    }  
                }) 
            } catch(error) { 
                setIsLoading(false); 
            } 
        } 
        initialScreen().then( () => {   
            setIsLoading(false) 
        }).catch(error => {             
            setIsLoading(false) 
        }); 

    }, [isBackMaster,shiftVM]) 

    if (isLoading) return <Loader Indicator="Stripe" size="Large" />; 

    return <> 
        <div className={`main-comp-wrapper im-hide-side-form-draggable`}> 
            <SliderPanel 
                isOpen={false} 
                draggable={false} 
                leftSectionWidth={"0%"} 
                rightSectionWidth={"100%"} 
                leftChildren={<ShiftTablePanel/>} 
                /> 
        </div> 
    </> 
} 
export default memo(ShiftMaintenance); 
