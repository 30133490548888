import { Permission } from "presentation/constant/ANAInfo/PermissionName"; 
import { ShiftMaintenanceProvider } from "presentation/store/Shift/ShiftMaintenanceProvider"; 
import { ANAInfoWrapper } from "presentation/view/components/ANAInfo/ANAInfoWrapper"; 
import { MessageBarWrapper } from "presentation/view/components/MessageBarWrapper"; 
import { ShiftMasterView } from "presentation/view/section/Shift/ShiftMasterView"; 
import { GridStyles } from "veronica-ui-component/dist/component/core/styled/table.styled"; 
const ShiftMaintenanceCont = () => { 
    return <ANAInfoWrapper permission={Permission.SHIFT_MAINTENANCE}> 
        <MessageBarWrapper> 
            <ShiftMaintenanceProvider> 
                <GridStyles/> 
                <ShiftMasterView/> 
            </ShiftMaintenanceProvider> 
        </MessageBarWrapper> 
    </ANAInfoWrapper> 
} 
export default ShiftMaintenanceCont; 
