import moment from "moment";
import { shiftDayDropdownOption, shiftTypeDropdownOption } from "presentation/constant/DropDownOptions/StaticDropdownOptions";
import { ShiftMaintenanceConstant } from "presentation/constant/Shift/ShiftMaintenanceConstant";
import { useShiftMaintenanceVM } from "presentation/hook/Shift/useShiftMaintenanceVM";
import { useShiftMaintenanceTracked } from "presentation/store/Shift/ShiftMaintenanceProvider";
import { CriteriaItemContainer } from "presentation/view/components/CriteriaItemContainer";
import { NbisCommonField } from "presentation/view/components/NbisCommonField";
import { useCallback, useMemo } from "react";
import { FieldType, HPHCheckbox, IFieldValue, TimePicker } from "veronica-ui-component/dist/component/core";
import { SidebarCaption, SidebarTitle, StyledSidebar } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";
import ShiftEditPanelTitleBar from "./ShiftEditPanelTitleBar";
export const ShiftMaintenanceFormPanel = () => { 
    const [shiftState] = useShiftMaintenanceTracked(); 
    const shiftVM = useShiftMaintenanceVM(); 
    const {currentSelectedRow, masterState} = shiftState; 
    const {editingEntity, isAdd, isEditable, isRead, isSaveClicked, allFormState} = masterState; 
    const SCREEN_CONSTANT = ShiftMaintenanceConstant; 

    const currentEntity = isRead? currentSelectedRow : editingEntity; 

    
    const getStartTime = (hours:number, min:number) => {
        const sysdate = moment();
        sysdate.hours(hours);
        sysdate.minutes(min);
        return sysdate
    }

    const isAheardTimeDisable = useCallback(() => {
        if(isAdd && currentEntity.shiftDay === 'PUBLIC_HOLIDAY'){
            if(currentEntity.startTimeHour!==null&&currentEntity.startTimeMin!==null){
                if(currentEntity.startTimeHour===0&&currentEntity.startTimeMin===0
                ) {
                    return false;
                }else{
                    shiftVM.restAheardTime();
                    return true;
                }
            }
        } 
        return true;
    },[currentEntity.shiftDay, currentEntity.startTimeHour, currentEntity.startTimeMin, isAdd, shiftVM])
    
    const isExtTimeDisable = useCallback(() => {
        if(currentEntity.shiftDay === 'PUBLIC_HOLIDAY' &&(currentEntity.endTimeHour!==null&&currentEntity.endTimeMin!==null)){
            if(currentEntity.endTimeHour===23&&currentEntity.endTimeMin===59
            ) {
                return false;
            }else{
                shiftVM.restExtTime();
                return true;
            }
        }
        return true;
    },[currentEntity.endTimeHour, currentEntity.endTimeMin, currentEntity.shiftDay, shiftVM])
    
    const memoShiftType = useMemo(() => 
        <div className='im-flex-row-item' style={{ width: "430px", marginBottom: "24px" }}> 
            <NbisCommonField 
                errorMessages={allFormState} 
                isReadOnly={!isAdd} 
                isShowOptional={false} 
                readOnlyValue={currentEntity?.shiftType || ''} 
                fieldValue={currentEntity?.shiftType} 
                fieldLabel={SCREEN_CONSTANT.SHIFT_TYPE} 
                isSaveClicked={isSaveClicked} 
                fieldType={FieldType.DROPDOWN} 
                fieldKey={'shiftType'} 
                isShowMissingError={true} 
                maxLength={60} 
                options={shiftTypeDropdownOption}
                requiredFieldList={[]} 
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>  
                    shiftVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />         
        </div> 
        , [allFormState, isAdd, currentEntity?.shiftType, SCREEN_CONSTANT.SHIFT_TYPE, isSaveClicked, shiftVM]) 

    const memoShiftDay = useMemo(() => 
        <div className='im-flex-row-item' style={{ width: "430px", marginBottom: "24px" }}> 
            <NbisCommonField 
                errorMessages={allFormState} 
                isReadOnly={!isAdd} 
                isShowOptional={false} 
                readOnlyValue={currentEntity?.shiftDay || ''} 
                fieldValue={currentEntity?.shiftDay} 
                fieldLabel={SCREEN_CONSTANT.SHIFT_DAY} 
                isSaveClicked={isSaveClicked} 
                fieldType={FieldType.DROPDOWN} 
                fieldKey={'shiftDay'} 
                isShowMissingError={true} 
                maxLength={60} 
                options={shiftDayDropdownOption}
                requiredFieldList={[]} 
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>  
                    shiftVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />         
        </div> 
        , [allFormState, isAdd, currentEntity?.shiftDay, SCREEN_CONSTANT.SHIFT_DAY, isSaveClicked, shiftVM]) 

        const memoShiftCode = useMemo(() => 
            <div className='im-flex-row-item' style={{ width: "430px", marginBottom: "24px" }}> 

                <NbisCommonField 
                    errorMessages={allFormState} 
                    isReadOnly={!(isEditable || isAdd)} 
                    isShowOptional={false} 
                    readOnlyValue={currentEntity?.shiftCode || ''} 
                    fieldValue={currentEntity?.shiftCode || ''} 
                    fieldLabel={SCREEN_CONSTANT.SHIFT_CODE} 
                    isSaveClicked={isSaveClicked} 
                    fieldType={FieldType.TEXT} 
                    fieldKey={'shiftCode'} 
                    isShowMissingError={true} 
                    maxLength={10} 
                    requiredFieldList={[]} 
                    onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>  
                        shiftVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />         
            </div> 
            , [allFormState, isEditable, isAdd, currentEntity?.shiftCode, SCREEN_CONSTANT.SHIFT_CODE, isSaveClicked, shiftVM]) 

        const memoStartTime = useMemo(() => 
            <div className='im-flex-row-item' style={{width:"200px", marginBottom:"24px"}}> 
                <TimePicker
                        width='200px'
                        maxLength={2}
                        disabled={!isAdd}
                        label={SCREEN_CONSTANT.START_TIME}
                        type="number"                    
                        minLength={2}
                        value={getStartTime(currentEntity.startTimeHour??0, currentEntity.startTimeMin??0).toDate()}
                        // value={currentEntity.startTime}
                        onChange={(val: any) => shiftVM.onTimePickerChange(val, 'startTimeHour', 'startTimeMin')}/>
            </div> 
        , [SCREEN_CONSTANT.START_TIME, currentEntity.startTimeHour, currentEntity.startTimeMin, isAdd, shiftVM]) 

        const memoEndTime = useMemo(() => 
            <div className='im-flex-row-item' style={{width:"200px", marginBottom:"24px"}}> 
                <TimePicker
                        width='200px'
                        maxLength={2}
                        disabled={!(isEditable || isAdd)}
                        label={SCREEN_CONSTANT.END_TIME}
                        type="number"                    
                        minLength={2}
                        value={getStartTime(currentEntity.endTimeHour??0, currentEntity.endTimeMin??0).toDate()}
                        // value={currentEntity.endTime}
                        onChange={(val: any) => shiftVM.onTimePickerChange(val, 'endTimeHour', 'endTimeMin')}/>
            </div> 
        , [SCREEN_CONSTANT.END_TIME, currentEntity.endTimeHour, currentEntity.endTimeMin, isAdd, isEditable, shiftVM]) 

        const memoAheadTime = useMemo(() => 
            <div className='im-flex-row-item' style={{width:"200px", marginBottom:"24px"}}> 
                <TimePicker
                        width='200px'
                        maxLength={2}
                        disabled={isAheardTimeDisable()}
                        label={SCREEN_CONSTANT.AHEAD_TIME}
                        type="number"                    
                        minLength={2}
                        value={getStartTime(currentEntity.aheadTimeHour??0, currentEntity.aheadTimeMin??0).toDate()}
                        // value={currentEntity.aheadTime}
                        onChange={(val: any) => shiftVM.onTimePickerChange(val, 'aheadTimeHour', 'aheadTimeMin')}/>
            </div> 
        , [isAheardTimeDisable, SCREEN_CONSTANT.AHEAD_TIME, currentEntity.aheadTimeHour, currentEntity.aheadTimeMin, shiftVM]) 

        const memoExtTime = useMemo(() => 
            <div className='im-flex-row-item' style={{width:"200px", marginBottom:"24px"}}> 
                <TimePicker
                        width='200px'
                        maxLength={2}
                        disabled={isExtTimeDisable()}
                        label={SCREEN_CONSTANT.EXTEND_TIME}
                        type="number"                    
                        minLength={2}
                        value={getStartTime(currentEntity.extendTimeHour??0, currentEntity.extendTimeMin??0).toDate()}
                        // value={currentEntity.extendTime}
                        onChange={(val: any) => shiftVM.onTimePickerChange(val, 'extendTimeHour', 'extendTimeMin')}/>
            </div> 
        , [SCREEN_CONSTANT.EXTEND_TIME, currentEntity.extendTimeHour, currentEntity.extendTimeMin, isExtTimeDisable, shiftVM]) 

        const memoActiveInd = useMemo(() => 
            <> 
                <div className='im-flex-row-item' style={{ width: "150px", marginBottom: "24px" }}> 

                <NbisCommonField 
                    fieldKey={"activeInd"} 
                    requiredFieldList={[]} 
                    fieldLabel={SCREEN_CONSTANT.ACTIVE_IND}  
                    fieldType={FieldType.TEXT}  
                    isReadOnly={true} 
                    onFieldChange={()=>{}}/> 
                <HPHCheckbox 
                    label={""} 
                    checked={currentEntity?.activeInd==='Y'} 
                    disabled={!(isEditable || isAdd)} 
                    onChange={(e) => shiftVM.onCheckboxChange(e.checked, "activeInd")} 
                /> 
            </div> 
            </> 
        , [SCREEN_CONSTANT.ACTIVE_IND, currentEntity?.activeInd, isAdd, isEditable, shiftVM]) 

    const memoEditPanelTitleBar = useMemo(() => { 
        return <ShiftEditPanelTitleBar/> 
    }, []) 
    return <StyledSidebar style={{ width: '100%', height: "100%", border: 'none', backgroundColor: '#FFFFFF', overflow: 'auto', padding: '0' }} position='right'> 
        {/* Header part of add or edit record */} 
        {memoEditPanelTitleBar} 
        {/* Readable and Editable Form */} 
        <div className={'add-edit-form'} style={{ height: 'calc(100vh - 125px)', maxHeight: 'calc(100vh - 125px)', overflow: 'auto' }}> 
                <div className="flex-row-auto"> 
                    <div className={'flex-row-item'}> 
                        <div className={'im-flex-row'}> 
                            <div className={'flex-row-item-stretch'}> 
                                <SidebarTitle>{!isAdd ? currentEntity.shiftCode : "NEW SHIFT"}</SidebarTitle> 
                                {<SidebarCaption>"All fields are required unless indicated as 'optional'"</SidebarCaption>} 
                            </div> 
                        </div> 

                        <CriteriaItemContainer> 
                            {memoShiftType} 
                        </CriteriaItemContainer> 
                        <CriteriaItemContainer> 
                            {memoShiftDay} 
                        </CriteriaItemContainer> 
                        <CriteriaItemContainer> 
                            {memoStartTime}  {memoEndTime} 
                        </CriteriaItemContainer> 
                        <CriteriaItemContainer> 
                            {memoAheadTime} {memoExtTime}
                        </CriteriaItemContainer> 
                        <CriteriaItemContainer> 
                            {memoShiftCode} 
                        </CriteriaItemContainer> 
                        <CriteriaItemContainer> 
                            {memoActiveInd} 
                        </CriteriaItemContainer> 
                    </div> 
                </div> 
            </div> 
        {/* Readable and Editable Form */} 
    </StyledSidebar> 
} 
