import { EMPTY_SHIFT_ENTITY, ShiftEntity } from "domain/entity/Shift/ShiftEntity";
import { BaseViewChangeSate } from "../BaseViewChangeState";
import { DropdownProps } from "../DropdownProps";
export interface TariffCodeDropdownOptions { 
    ShiftTypeDropdownOptions: DropdownProps[], 
} 
export interface MasterChangeState extends BaseViewChangeSate { 
    editingEntity: ShiftEntity, 
} 
export interface ShiftMaintenanceModel { 
    isLoading: boolean, 
    tableData: ShiftEntity[], 
    currentSelectedRow: ShiftEntity, 
    updatedRows: ShiftEntity[], 
    selectedRows: ShiftEntity[], 
    dynamicOptions: TariffCodeDropdownOptions, 
    masterState: MasterChangeState, 
    isBackMaster: boolean, 
    isShowEditPanel: boolean, 
} 
export const EMPTY_MASTER_SHIFTANY_MODEL: ShiftMaintenanceModel = { 
    isLoading: false, 
    tableData: [], 
    currentSelectedRow: { ...EMPTY_SHIFT_ENTITY }, 
    selectedRows: [], 
    updatedRows: [], 
    dynamicOptions: { 
        ShiftTypeDropdownOptions: [] 
    }, 
    masterState: { 
        isSliderOpen: false, 
        isTabularDataActive: false, 
        isAdd: false, 
        isRead: false, 
        isEditable: false, 
        isSaveClicked: false, 
        lastEditRowId: "", 
        isSelected: false, 
        slider: { 
            isAdd: false, 
            isRead: false, 
            isEditable: false 
        }, 
        editingEntity: EMPTY_SHIFT_ENTITY, 
    }, 
    isBackMaster: false, 
    isShowEditPanel: false, 
} 
