import * as yup from 'yup';
export const commonShiftMaintenanceValidation: { [x: string]: any; } = { 
  shiftType: yup.string().required("Missing"), 
  shiftDay: yup.string().required("Missing"), 
  shiftCode: yup.string().required("Missing"), 
  // startTime: yup.date().required("Missing"), 
  // endTime: yup.date().required("Missing"), 
}; 
export const createShiftMaintenanceValidation: { [x: string]: any; } = { 
  ...commonShiftMaintenanceValidation, 
}; 
export const createShiftMaintenanceValidationSchema = yup.object().shape(createShiftMaintenanceValidation); 
