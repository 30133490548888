import { ResponseEntity } from "domain/entity/Common/ResponseEntity";
import { ShiftEntity } from "domain/entity/Shift/ShiftEntity";
import _ from "lodash";
import { axiosDeleteData, axiosGetData, axiosPostData, axiosPutData } from "../axios/AxiosBasicImpl";
import referenceAxiosInstance from "../axios/referenceAxiosInstance";
import { ShiftRepository } from "./ShiftRepo";
/** 
 * For template 
 * @returns  
 */
export const ShiftRepoImpl = (): ShiftRepository => {
    const url = '/v1/shift';
    const getEntities = async (): Promise<ShiftEntity[]> => {
        return await axiosGetData(referenceAxiosInstance, url, []).then(res => {
            return _.sortBy(res.data, ["priority"]);
        }).catch(err => {
            return [];
        });

    }
    const getEntityByKey = async (key: string): Promise<ShiftEntity> => {
        return await axiosGetData(referenceAxiosInstance, `${url}/${key}`, []).then(res => {
            return res.data;
        }).catch(err => {
            return null;
        });
    }
    const createEntity = async (newData: ShiftEntity): Promise<ResponseEntity> => {
        return await axiosPostData(referenceAxiosInstance, `${url}`, newData);
    }
    const updateEntity = async (updatedData: ShiftEntity): Promise<ResponseEntity> => {
        return await axiosPutData(referenceAxiosInstance, `${url}`, updatedData);
    }
    const applyEntity = async (applyData: ShiftEntity[]): Promise<ResponseEntity> => {
        const applyUrl = '/v1/shiftApply';
        return await axiosPostData(referenceAxiosInstance, `${applyUrl}`, applyData);
    }
    const deleteEntity = async (key: string): Promise<ResponseEntity> => {
        return await axiosDeleteData(referenceAxiosInstance, `${url}`, key);
    }

    const getShiftCodeForComboBox = async() : Promise<ShiftEntity[]> => {
        const newUrl = '/v1/shiftCodeForComboBox';
        return axiosGetData(referenceAxiosInstance, newUrl, []).then(res => {
            const data = res.data;
            return _.sortBy(data, ["key"]);
        }).catch(err => {
            return [];
        })
        
    }
    return {
        getEntities: getEntities,
        getEntityByKey: getEntityByKey,
        createEntity: createEntity,
        updateEntity: updateEntity,
        applyEntity: applyEntity,
        deleteEntity: deleteEntity,
        getShiftCodeForComboBox: getShiftCodeForComboBox,
    }
} 
