import { ResponseEntity } from "domain/entity/Common/ResponseEntity";
import { EMPTY_SHIFT_ENTITY, ShiftEntity } from "domain/entity/Shift/ShiftEntity";
import { ShiftRepository } from "domain/repository/Shift/ShiftRepo";
import _ from "lodash";
import { createShiftMaintenanceValidationSchema } from "presentation/constant/Shift/ShiftMaintenanceValidationSchema";
import { Validation } from "presentation/constant/Validation";
import { ShiftMaintenanceModel } from "presentation/model/Shift/ShiftMaintenanceModel";
import { Dispatch, SetStateAction } from "react";
import { IFieldValue } from "veronica-ui-component/dist/component/core";
import BaseViewModel from "../BaseViewModel";
interface ShiftMaintenanceVMProps extends BaseViewModel { 
    dispatch: [ 
        Dispatch<SetStateAction<ShiftMaintenanceModel>> | ((value: SetStateAction<ShiftMaintenanceModel>) => void), 
    ], 
    repo: ShiftRepository, 
} 
export const ShiftMaintenanceVM = ({dispatch,repo}:ShiftMaintenanceVMProps) => { 
    const [shiftMainDispatch] = dispatch; 

    const loadDropdownOption = async () => { 
    } 
    const onSearch = async () => { 
        await repo.getEntities().then(data =>{
            shiftMainDispatch(prevState =>({ 
                ...prevState, 
                tableData: data, 
                selectedRows: [], 
                updatedRows: [], 
                currentSelectedRow: EMPTY_SHIFT_ENTITY, 
                isBackMaster: false, 
            })) 

        })
    } 
    const updateSelectedRows = async (rows:ShiftEntity[]) => { 
        shiftMainDispatch(prevState => { 

            return { 
                ...prevState, 
                // tableData: [...allRows], 
                selectedRows: rows, 
            } 
        }) 
    } 
    const onAdd = () => { 
        shiftMainDispatch(prevState => { 

            return { 
                ...prevState, 
                isShowEditPanel: true,
                masterState:{ 
                    ...prevState.masterState, 
                    isAdd: true, 
                    isEditable: false, 
                    isRead: false, 
                    editingEntity: EMPTY_SHIFT_ENTITY, 
                } 
            } 
        }) 
    } 
    const onEdit = (currentEntity: ShiftEntity)=>{ 
        shiftMainDispatch(prevState => { 
            return { 
                ...prevState, 
                currentSelectedRow: currentEntity, 
                isShowEditPanel: true,
                masterState:{ 
                    ...prevState.masterState, 
                    isAdd: false, 
                    isEditable: false, 
                    isRead: false, 
                    editingEntity: { 
                        ...currentEntity 
                    }, 
                } 
            } 
        }) 
    } 
    const onEditClick = (currentEntity: ShiftEntity)=>{ 
        shiftMainDispatch(prevState => { 
            return { 
                ...prevState, 
                currentSelectedRow: currentEntity, 
                isShowEditPanel: true,
                masterState:{ 
                    ...prevState.masterState, 
                    isAdd: false, 
                    isEditable: true, 
                    isRead: false, 
                    editingEntity: { 
                        ...currentEntity 
                    }, 
                } 
            } 
        }) 
    } 
    const onReset = ()=>{ 
        shiftMainDispatch(prevState => { 
            const resetEntity = prevState.masterState.isAdd ? EMPTY_SHIFT_ENTITY : prevState.currentSelectedRow; 
            return { 
                ...prevState, 
                masterState:{ 
                    ...prevState.masterState, 
                    editingEntity: { 
                        ...resetEntity 
                    }, 
                } 
            } 
        }) 
    } 
    const onClose = () => { 
        shiftMainDispatch(prevState => { 
            return { 
                ...prevState, 
                currentSelectedRow: EMPTY_SHIFT_ENTITY, 
                selectedRows: [], 
                masterState: { 
                    ...prevState.masterState, 
                    isAdd: false, 
                    isEditable: false, 
                    isRead: true, 
                    editingEntity: EMPTY_SHIFT_ENTITY, 
                    allFormState:{} 
                }, 
                isBackMaster: true, 
                isShowEditPanel: false,
            } 
        }) 
    } 
    const onSaveClicked = () => { 
        shiftMainDispatch(prevState => { 
            return { 
                ...prevState, 
                masterState: { 
                    ...prevState.masterState, 
                    isSaveClicked: true, 
                    allFormState: {}, 
                } 
            } 
        }) 
    } 
    const onSave = async (currentEntity: ShiftEntity,isAdd: boolean) => { 
        const valResult = isAdd? await Validation(createShiftMaintenanceValidationSchema).ValidateFormOnly(currentEntity) : null; 
        let validatedResult: {[x: string]: string} = {};   
        if (valResult) { 
            validatedResult = {...valResult, warningMessage: 'Please input the missing value.' };  

            shiftMainDispatch(prevState => { 
                return { 
                    ...prevState, 
                    masterState: { 
                        ...prevState.masterState, 
                        allFormState: { 
                            ...validatedResult 
                        }, 
                    }                         
                } 
            }); 
            const res: ResponseEntity={
                code: "",
                success: false,
                msg: null,
                data: 'Please input the missing value.'
            }

            return res;
        } 
        if(isAdd){ 
            return await repo.createEntity(currentEntity); 
        }else{ 
            return await repo.updateEntity(currentEntity); 
        } 
    } 
    const onCheckboxChange = (checked: boolean, fieldName: string) => { 
        shiftMainDispatch(prevState => ({ 
            ...prevState, 
            masterState:{ 
                ...prevState.masterState, 
                editingEntity : { 
                    ...prevState.masterState.editingEntity, 
                    [fieldName]: checked?"Y":"N", 
                } 
            } 
        })) 
    } 
    const onFieldChange = async (fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => { 
        let val: any = fieldValue; 
        if (_.isArray(val)) { 
            val = _.uniq(val?.map((item: any) => item.value || item.key)); 
        } 
        if(fieldKey === 'Shift'){ 
            val = val.toUpperCase().replace(/\s+/g, ''); 
        } 
        shiftMainDispatch(prevState => { 
            return { 
                ...prevState, 
                masterState:{ 
                    ...prevState.masterState, 
                    editingEntity: { 
                        ...prevState.masterState.editingEntity, 
                        [fieldKey]: val, 
                    }, 
                    allFormState:{ 
                        ...prevState.masterState.allFormState, 
                        [fieldKey]: '', 
                    } 
                } 
            } 
        }) 
    } 

    const onTimePickerChange = (value: Date, hourName: string, minName: string) => {
        const hours = value.getHours();
        const mins = value.getMinutes();
        return shiftMainDispatch(prevState => {
            return {
                ...prevState,
                masterState: {
                    ...prevState.masterState,
                    editingEntity: {
                        ...prevState.masterState.editingEntity,
                        [hourName]: hours,
                        [minName]: mins,
                    }
                }          
            }
          });
    }

    // const onRowDrag = (rows: ShiftEntity[]) => {
    //     shiftMainDispatch(prevState =>{ 
    //         const prevRows = prevState.tableData;
    //         const toChangedRows = rows.map((e, index) => ({...e,tempPriority: prevRows[index].priority})).filter(e=>e.tempPriority!==e.priority);

    //         return ({
    //             ...prevState,
    //             tableData: rows,
    //             updatedRows: toChangedRows,
    //     })})
    // }

    const onApply = async (rows: ShiftEntity[]) => {
        return await repo.applyEntity(rows);
    }

    const restAheardTime = async () => {
        shiftMainDispatch(prevState =>{ 
            return ({
                ...prevState,
                masterState: {
                    ...prevState.masterState,
                    editingEntity: {
                        ...prevState.masterState.editingEntity,
                        aheadTimeHour: null,
                        aheadTimeMin: null,
                    }
                }          
        })})
    }
    const restExtTime = async () => {
        shiftMainDispatch(prevState =>{ 
            return ({
                ...prevState,
                masterState: {
                    ...prevState.masterState,
                    editingEntity: {
                        ...prevState.masterState.editingEntity,
                        extendTimeHour: null,
                        extendTimeMin: null,
                    }
                }          
        })})
    }

    return { 
        restExtTime: restExtTime, 
        restAheardTime: restAheardTime, 
        onApply: onApply, 
        // onRowDrag: onRowDrag, 
        loadDropdownOption: loadDropdownOption, 
        updateSelectedRows: updateSelectedRows, 
        onAdd: onAdd, 
        onEdit: onEdit, 
        onReset: onReset, 
        onClose: onClose, 
        onSearch: onSearch, 
        onSaveClicked: onSaveClicked, 
        onSave: onSave, 
        onCheckboxChange: onCheckboxChange, 
        onFieldChange: onFieldChange, 
        onTimePickerChange: onTimePickerChange,
        onEditClick: onEditClick,
    } 
} 
