import { ShiftRepoImpl } from "domain/repository/Shift/ShiftRepoImpl"; 
import { useShiftMaintenanceTracked } from "presentation/store/Shift/ShiftMaintenanceProvider"; 
import { ShiftMaintenanceVM } from "presentation/viewModel/Shift/ShiftMaintenanceVM"; 
import { useMemo } from "react"; 
export const useShiftMaintenanceVM = () => { 
    const [, setShiftMaintenanceState] = useShiftMaintenanceTracked(); 
    const ShiftMainVM = useMemo(() => 
        ShiftMaintenanceVM({ 
            dispatch: [setShiftMaintenanceState], 
            repo: ShiftRepoImpl(), 
        }), [setShiftMaintenanceState]) 
    return ShiftMainVM 
} 
